import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Thing We Should Know`}</h2>
    <p>{`Writing pattern is lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`}</p>
    <h2>{`Titles`}</h2>
    <p>{`Capitalize the first letter of every word except the conjuctions, propositions, and articles.
`}<strong parentName="p">{`Maximum Characters: 32 characters`}</strong></p>
    <h3><strong parentName="h3">{`Example`}</strong></h3>
    <p><strong parentName="p">{`Page Title`}</strong>{`:
Pendaftaran Akun`}</p>
    <p><strong parentName="p">{`Onboarding Title`}</strong>{`:
Selamat Datang di OSS Berbasis Risiko`}</p>
    <p><strong parentName="p">{`Breadcrumbs`}</strong>{`:
Skala Usaha > Verifikasi Data > Kata Sandi > Profil Pelaku Usaha”`}</p>
    <h2>{`Descriptions`}</h2>
    <p>{`Informational chunk of text (body text). Make it easy to use as possible. Write maximum three lines to help user digest the information. Be honest and tell the specific what has happened. Prioritize to use active voice.
Avoid repetitive words. Use period for end sentence.`}</p>
    <p>{`Description can be shown for a pop-up (body text), empty state, or additional text to describe the title field (error message).`}</p>
    <h3><strong parentName="h3">{`Example`}</strong></h3>
    <p>{`Title: `}<strong parentName="p">{`Menu Ini Bisa Diakses Setelah Memiliki NIB`}</strong>{`
Description: Anda harus memiliki Nomor Induk Berusaha (NIB) untuk mengakses menu ini.
CTA: Mengerti`}</p>
    <p>{`Title field: `}<strong parentName="p">{`Nomor SK Pengesahan Terakhir`}</strong>{`
Placeholder/hint: Masukkan nomor SK Pengesahan Terakhir
Description: SK pengesahan dikeluarkan oleh Ditjen AHU Kemenkumham ”`}</p>
    <h2>{`Section Title`}</h2>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`}</p>
    <h3><strong parentName="h3">{`Example`}</strong></h3>
    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      